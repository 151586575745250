














































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";

import { mapActions, mapGetters } from "vuex";
import rules from "@/components/account/rules";
import { notifyError, notifyInfo } from "@/components/Notification";
import { AxiosError } from "axios";

export default Vue.extend({
  name: "btn-create-nomenclator",
  data() {
    return {
      dialog: false,
      formValid: false,
      minprice: 0,
      maxprice: 0,
      price: 0,
      rules,
      loading: false,
      form: {
        name: "",
        type: "Treatment",
        abbreviation: "",
      },
      proceduresTypes: [
        { label: "Surgery", value: "Surgery" },
        { label: "Treatment", value: "Treatment" },
      ],
      rulesabs: [(v: any) => v.length <= 7 || "Max 25 characters"],
    };
  },
  computed: {
    ...mapGetters(["getShowPrice"]),
    PriceValid() {
      if (this.minprice == 0 && this.maxprice == 0 && this.price == 0) {
        return true;
      }
      if (this.minprice != 0 || this.maxprice != 0) {
        if (
          Number(this.minprice) > Number(this.maxprice) &&
          Number(this.price) <= Number(this.minprice) &&
          Number(this.price) >= Number(this.maxprice)
        ) {
          return true;
        } else if (
          Number(this.minprice) < Number(this.maxprice) &&
          Number(this.price) >= Number(this.minprice) &&
          Number(this.price) <= Number(this.maxprice)
        ) {
          return true;
        }
        if (this.minprice == this.maxprice && this.price == this.minprice) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actCreateSubtypeNomenclator"]),
    create(): void {
      if (Number(this.minprice) > Number(this.maxprice)) {
        const temp = this.minprice;
        this.minprice = this.maxprice;
        this.maxprice = temp;
      }
      let body = {};
      this.loading = true;
      if (this.getShowPrice) {
        if (this.minprice != 0 && this.maxprice != 0) {
          body = {
            name: this.form.name,
            type: this.form.type,
            abbreviation: this.form.abbreviation,
            minValue: Number(this.minprice),
            maxValue: Number(this.maxprice),
            price: Number(this.price),
          };
        } else {
          if (this.maxprice != 0) {
            body = {
              name: this.form.name,
              type: this.form.type,
              abbreviation: this.form.abbreviation,
              maxValue: Number(this.maxprice),
              price: Number(this.price),
            };
          } else {
            body = {
              name: this.form.name,
              type: this.form.type,
              abbreviation: this.form.abbreviation,
            };
          }
        }
      } else {
        body = {
          name: this.form.name,
          type: this.form.type,
          abbreviation: this.form.abbreviation,
        };
      }
      this.actCreateSubtypeNomenclator(body)
        .then(() => {
          this.$emit("typecreated", this.form.type);
          this.loading = false;
          this.form.name = "";
          (this as any).$refs.form.resetValidation();
          notifyInfo("The procedure subtype has been created successfully");
          this.close();
        })
        .catch((error: any) => {
          this.loading = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    close() {
      this.dialog = false;
      this.form.name = "";
      this.minprice = 0;
      this.maxprice = 0;
    },
  },
});
